import React from "react";
import clsx from "clsx";
import { useRouter } from "next/router";
import { Menu as HUIMenu } from "@headlessui/react";
import { Trans } from "@lingui/macro";
import {
  Button,
  ButtonBurger,
  Brand,
  Container,
  List,
  ListItem,
  Drawer,
} from "ui";
import LanguageSwitcher from "components/language-switcher/LanguageSwitcher";
import { bottomNavigationRoutes, topNavigationRoutes } from "routes";
import buttonClasses from "ui/src/components/button/button.module.scss";
import _classes from "./header.module.scss";
import Link from "next/link";
import { Youtube as YoutubeIcon, SearchIcon } from "ui/src/components/icons";
import VerbsAutosuggestMobile from "components/verbs-autosuggest-mobile";
import { AsidePromotion } from "components/aside-promotion";
import { LOCALES } from "types";

const YOUTUBE_LINK = 'https://www.youtube.com/@professeurfabien';

function NavigationSeparator() {
  return (
    <ListItem
      className={clsx(_classes.navItem, _classes.navSeparator)}
      role="separator"
    />
  );
}

function YoutubeCTA() {
  return (
    <Trans
      id="Suivez-moi sur Youtube"
      render={({ translation }) => (
        <a
          href={YOUTUBE_LINK}
          target="_blank"
          rel="noreferrer"
          //@ts-ignore
          title={translation}
        >
          <Button className={clsx(_classes.navAction, _classes.youtubeCta)}>
            <YoutubeIcon />
          </Button>
        </a>
      )}
    ></Trans>
  );
}

function SearchVerbCTA() {
  const router = useRouter();
  const onVerbSelected = React.useCallback(
    (verb) => {
      if (!verb) {
        return;
      }
      router.push(`/verbs/${verb.id}`);
    },
    [router]
  );

  return (
    <VerbsAutosuggestMobile
      onSuggestionSelected={onVerbSelected}
      ButtonComponent={(props) => (
        <Button
          className={clsx(_classes.navAction, _classes.searchVerbCTA)}
          {...props}
        >
          <SearchIcon />
          <span>
            <Trans>Recherche...</Trans>
          </span>
        </Button>
      )}
    />
  );
}

function TopNavigation({ className }: React.BaseHTMLAttributes<HTMLElement>) {
  return (
    <nav
      role="navigation"
      className={className}
      aria-label="Navigation sur ordinateur"
    >
      <List>
        {topNavigationRoutes.map((route) => (
          <ListItem key={route.name} className={_classes.navItem}>
            <Trans
              id={route.name}
              render={({ translation }) => (
                <Link href={route.path}>
                  <a className={_classes.link}>{translation}</a>
                </Link>
              )}
            />
          </ListItem>
        ))}
      </List>
    </nav>
  );
}

function NavLanguageSwitcherControl({
  title,
  Flag,
}: {
  title: string;
  Flag: React.ElementType;
}) {
  return (
    <HUIMenu.Button
      className={clsx(
        buttonClasses.root,
        buttonClasses.primary,
        _classes.navAction
      )}
      title={title}
    >
      <Flag />
    </HUIMenu.Button>
  );
}

function DrawerLanguageSwitcherControl({ title }: { title: string }) {
  return (
    <HUIMenu.Button
      title={title}
      className={clsx(
        buttonClasses.root,
        buttonClasses.primary,
        _classes.drawerLangSwitcherButton,
        "h6"
      )}
    >
      {title}
    </HUIMenu.Button>
  );
}

function Header({ locale = 'fr' }: { locale: LOCALES}) {
  const [drawerOpened, setDrawerOpened] = React.useState(false);
  const toggleDrawer = () => setDrawerOpened(!drawerOpened);
  const closeDrawer = () => setDrawerOpened(false);
  return (
    <header role="banner" className={_classes.root}>
      <Drawer open={drawerOpened} onCloseRequested={closeDrawer}>
        <List className={_classes.drawerMenu}>
          {bottomNavigationRoutes.map((route) => (
            <ListItem
              key={`drawer-nav-${route.name}`}
              className={_classes.drawerMenuItem}
            >
              <Trans
                id={route.name}
                render={({ translation }) => (
                  <Link href={route.path}>
                    <a className="h6" onClick={closeDrawer}>
                      {translation}
                    </a>
                  </Link>
                )}
              />
            </ListItem>
          ))}
          <ListItem key="drawer-nav-divider-1" className={_classes.divider}>
            <div className={_classes.drawerDivider} />
          </ListItem>
          <ListItem key="language-switcher">
            <LanguageSwitcher ButtonComponent={DrawerLanguageSwitcherControl} />
          </ListItem>
          <ListItem key="drawer-nav-divider-2" className={_classes.divider}>
            <div className={_classes.drawerDivider} />
          </ListItem>
          <ListItem key="youtube-cta" className={clsx(_classes.drawerMenuItem, _classes.drawerYoutubeCTA)}>
            <AsidePromotion locale={locale} mode="dark" />
          </ListItem>
        </List>
      </Drawer>
      <Container className={_classes.container}>
        <div className={_classes.brandWrapper}>
          <ButtonBurger onClick={toggleDrawer} />
          <Brand classes={{ icon: _classes.brandIcon }} href="/" />
        </div>
        <TopNavigation className={_classes.desktop} />
        <div className={_classes.clear} />
        <SearchVerbCTA />
        <NavigationSeparator />
        <LanguageSwitcher ButtonComponent={NavLanguageSwitcherControl} />
        <NavigationSeparator />
        <YoutubeCTA />
      </Container>
    </header>
  );
}

export default Header;
