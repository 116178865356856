import clsx from "clsx";
import React from "react";
import _classes from "./button.module.scss";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  outlined?: boolean;
  text?: boolean;
  contained?: boolean;
  color?: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

function Button({
  className,
  outlined = false,
  text = false,
  contained = false,
  fullWidth = false,
  color,
  disabled = false,
  children,
  ...rest
}: ButtonProps) {
  return (
    <button
      className={clsx(
        _classes.root,
        {
          [_classes.primary]: color === "primary",
          [_classes.secondary]: color === "secondary",
          [_classes.text]: text,
          [_classes.contained]: contained,
          [_classes.outlined]: outlined,
          [_classes.fullWidth]: fullWidth,
          [_classes.disabled]: disabled,
        },
        className
      )}
      type="button"
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
}

export default Button;
