interface Props {
  className?: string;
  children?: any;
  width?: number;
  height?: number;
}

export function createSvgIcon(
  svg: any,
  name: string,
  { width = 24, height = 24 }: { width?: number; height?: number } = {}
) {
  const SvgIconComponent = ({ className }: { className?: string }) => (
    <SvgIcon className={className} width={width} height={height}>
      {svg}
    </SvgIcon>
  );
  SvgIconComponent.displayName = name;
  return SvgIconComponent;
}

export default function SvgIcon({
  className,
  children,
  width = 24,
  height = 24,
}: Props) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      fill="white"
      width={width}
      height={height}
    >
      {children}
    </svg>
  );
}
