import clsx from "clsx";
import _classes from "./list-item.module.scss";

function ListItem({
  className,
  children,
  ...rest
}: React.LiHTMLAttributes<HTMLLIElement>) {
  return (
    <li className={clsx(_classes.root, className)} {...rest}>
      {children}
    </li>
  );
}

export default ListItem;
