import React, { SetStateAction, Dispatch } from "react";
import { createPortal } from "react-dom";
import { createWrapperAndAppendToBody } from "./utils";

interface Props {
  children?: React.ReactNode | string;
  wrapperId: string;
}

function ReactPortal({ children, wrapperId }: Props) {
  const [wrapperElement, setWrapperElement] =
    React.useState<HTMLElement | null>(null);

  React.useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let isSystemCreated = false;
    if (!element) {
      isSystemCreated = true;
      element = createWrapperAndAppendToBody(wrapperId);
    }
    setWrapperElement(element);

    return () => {
      if (isSystemCreated && element && element.parentNode) {
        element?.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
}

export default ReactPortal;
