import React from "react";
import { Trans } from "@lingui/macro";
import { bottomNavigationRoutes } from "routes";
import _classes from "./mobile-bottom-menu.module.scss";
import Link from "next/link";
import { HomeIcon, PencilSquareIcon, SearchIcon, List, ListItem } from "ui";

const iconsMapping: { [key: string]: React.ElementType } = {
  accueil: HomeIcon,
  recherche: SearchIcon,
  exercice: PencilSquareIcon,
};

function renderIcon(iconId: string) {
  const Icon = iconsMapping[iconId];
  if (!Icon) {
    return null;
  }
  return <Icon className={_classes.icon} />;
}

function MobileBottomMenu() {
  return (
    <List className={_classes.root}>
      {bottomNavigationRoutes.map((route) => (
        <ListItem
          key={`mobile-bottom-nav-${route.name}`}
          className={_classes.listItem}
        >
          <Trans
            id={route.name}
            render={({ translation }) => (
              <Link href={route.path}>
                <a className="caption">
                  {renderIcon(route.name)}
                  <span>{translation}</span>
                </a>
              </Link>
            )}
          />
        </ListItem>
      ))}
    </List>
  );
}

export default MobileBottomMenu;
