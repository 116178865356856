import { Fragment, useEffect, useRef } from 'react';
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import Layout from "components/layout/Layout";
import { Head } from "components/head";
import { GoogleAnalytics } from "components/google-analytics";
import { initTranslation, useIsMobile } from "utils";
import { config as faConfig } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

import config from "../config";
import "../styles/global.scss";
import { LOCALES } from 'types';

initTranslation(i18n);
faConfig.autoAddCss = false;


interface CustomAppProps extends AppProps {
  pageProps: AppProps["pageProps"] & {
    translation: any,
    isMobile: boolean,
  }
}


function MyApp({ Component, pageProps }: CustomAppProps) {
  const router = useRouter();
  const isMobile = useIsMobile();
  const { locale = router.defaultLocale } = router;
  const firstRender = useRef(true);

  if (pageProps.translation && firstRender.current) {
    //load the translations for the locale
    // @ts-ignore
    i18n.load(locale, pageProps.translation);
    // @ts-ignore
    i18n.activate(locale);
    // render only once
    firstRender.current = false;
  }

  useEffect(() => {
    if (pageProps.translation) {
      const _locale = locale || "fr";
      i18n.load(_locale, pageProps.translation);
      i18n.activate(_locale);
    }
  }, [locale, pageProps.translation]);

  pageProps.isMobile = isMobile;
  return (
    <I18nProvider i18n={i18n}>
      <Fragment>
        <Head />
        <Layout {...pageProps} locale={locale as LOCALES}>
          <GoogleAnalytics googleAnalyticsId={config.googleAnalyticsId}/>
          <Component {...pageProps} locale={locale} />
        </Layout>
      </Fragment>
    </I18nProvider>
  );
}

export default MyApp;
