import React from "react";
import clsx from "clsx";
import _classes from "./verbs-autosuggest-suggestion.module.scss";

interface Props {
  name: string;
  translation?: string;
}

function VerbsAutosuggestSuggestion({ name, translation }: Props) {
  return (
    <div className={_classes.root}>
      <span className={_classes.source}>{name}</span>
      {translation && translation !== name ? (
        <span className={clsx(_classes.translation, "caption")}>
          {translation}
        </span>
      ) : null}
    </div>
  );
}

export default VerbsAutosuggestSuggestion;
