import clsx from "clsx";
import { Menu as HUIMenu } from "@headlessui/react";
import _classes from "./menu-item.module.scss";
import React from "react";

function MenuItem({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  return (
    <HUIMenu.Item {...props}>
      <div className={clsx(_classes.root, className)}>{children}</div>
    </HUIMenu.Item>
  );
}

export default MenuItem;
