import React from "react";
import Button from "../button/Button";
import styles from "./button-burger.module.scss";

function IconBar() {
  return <span className={styles.iconBar}></span>;
}

function ButtonBurger({
  onClick,
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <Button className={styles.root} onClick={onClick}>
      <React.Fragment>
        <span className="sr-only">Toggle navigation</span>
        <span className={styles.icon}></span>
        <span className={styles.icon}></span>
        <span className={styles.icon}></span>
      </React.Fragment>
    </Button>
  );
}

export default ButtonBurger;
