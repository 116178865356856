import NextHead from "next/head";
import { t } from "@lingui/macro";

export default function Head() {
  const title = 'Verbfab';
  const description = t`Conjugaison et exercices des verbes de la language francaise`;
  return (
    <NextHead>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <link rel="manifest" href="/assets/favicons/site.webmanifest" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="application-name" content={title} />	
      <link rel="shortcut icon" href="/assets/favicons/favicon.ico" />
      <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicons/favicon-16x16.png" />
      <meta name="apple-mobile-web-app-title" content={title} />
      <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicons/apple-touch-icon.png" />
      <link rel="mask-icon" href="/assets/favicons/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="msapplication-config" content="/assets/favicons/browserconfig.xml" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://verbfab.com/" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="/assets/social_image.png" />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://verbfab.com/" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content="/social_inmage.png" />

      <link rel="alternate" href="https://verbfab.com" hrefLang="x-default" />
      <link rel="alternate" href="https://verbfab.com/ko" hrefLang="ko" />
    </NextHead>
  );
}