import clsx from "clsx";
import { Menu as HUIMenu } from "@headlessui/react";
import _classes from "./menu.module.scss";
import React from "react";

function Menu({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  return (
    <div className={clsx(_classes.root, className)}>
      <HUIMenu {...props}>{children}</HUIMenu>
    </div>
  );
}

export default Menu;
