import React from "react";
import clsx from "clsx";
import _classes from "./loader.module.scss";

interface Props {
  className?: string | null;
  color?: "primary" | "secondary";
}

function Loader({ className = null, color = "primary" }: Props) {
  return (
    <span
      className={clsx(_classes.root, className, {
        [_classes.primary]: color === "primary",
        [_classes.secondary]: color === "secondary",
      })}
    />
  );
}

export default Loader;
