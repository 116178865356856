import React from "react";
import Header from "../header/Header";
import { MobileBottomMenu } from "components/mobile-bottom-menu";
import { Container } from "ui";
import { AsidePromotion } from "components/aside-promotion";
import _classes from "./layout.module.scss";
import { LOCALES } from "types";

function Layout({ children, locale = 'fr' }: { children: any, locale: LOCALES }) {
  return (
    <div className={_classes.root}>
      <Header locale={locale} />
      <Container className={_classes.main}>
        <Container className={_classes.content}>
          <Container className={_classes.aside}>
            <AsidePromotion locale={locale} />
          </Container>
          <Container className={_classes.playground}>
            {children}
          </Container>
        </Container>
      </Container>
      <MobileBottomMenu />
    </div>
  );
}

export default Layout;
