import clsx from "clsx";
import { XCircleIcon } from "@heroicons/react/20/solid";
import _classes from "./chip.module.scss";

interface Props {
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  value: string;
}

function Chip({ onClick, value }: Props) {
  return (
    <div className={_classes.root} title={value}>
      <span className={clsx(_classes.text, "caption")}>{value}</span>
      <span
        className={_classes.action}
        role="button"
        tabIndex={0}
        onClick={onClick}
      >
        <XCircleIcon aria-hidden="true" />
      </span>
    </div>
  );
}

export default Chip;
