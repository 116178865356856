import { createSvgIcon } from "./SvgIcon";

const ArrowLeft = createSvgIcon(
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
  />,
  "ArrowLeft"
);

export default ArrowLeft;
