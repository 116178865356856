import clsx from "clsx";
import { Menu as HUIMenu } from "@headlessui/react";
import _classes from "./menu-items.module.scss";

interface Props {
  children: any;
  className?: string;
}

function MenuItems({ children, className, ...props }: Props) {
  return (
    <HUIMenu.Items className={clsx(_classes.root, className)} {...props}>
      {children}
    </HUIMenu.Items>
  );
}

export default MenuItems;
