import React from "react";
import type { I18n } from "@lingui/core";
import { fr } from "make-plural/plurals";
import { GetStaticProps } from "next";

import { Verb } from "./types";

export function initTranslation(i18n: I18n): void {
  i18n.loadLocaleData({
    fr: { plurals: fr },
    ko: { plurals: fr },
  });
}

export async function loadTranslation(locale: string, isProduction = true) {
  let data;
  if (isProduction) {
    data = await import(`./translations/locales/${locale}/messages`);
  } else {
    data = await import(
      `@lingui/loader!./translations/locales/${locale}/messages.po`
    );
  }
  return data.messages;
}

//@ts-ignore
export const getStaticTranslations = async (ctx: { locale?: string }) => {
  const translation = await loadTranslation(
    ctx.locale!,
    process.env.NODE_ENV === "production"
  );
  return translation;
};

export const getStaticTranslationProps: GetStaticProps = async (ctx) => {
  const translation = await getStaticTranslations(ctx);
  return {
    props: {
      translation,
    },
  };
};

// Dummy way to determine if we need to show the mobile layout but sufficient
// for our use case
export function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    //@ts-ignore
    setIsMobile(window.innerWidth < 576);
  }, []);
  return isMobile;
}

export function formatVerb(infos: any, targetTranslation?: string) {
  const {
    translations = [],
    ...rest
  } = infos;
  return { ...rest, translations: translations || [] };
}
