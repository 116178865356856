import Script from 'next/script';

export default function GoogleAnalytics({ googleAnalyticsId } : { googleAnalyticsId?: string }) {
  if (!googleAnalyticsId) {
    return null;
  }
  return (
    <>
      <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}/>
      <Script
        id='google-analytics'
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${googleAnalyticsId}', {
              page_path: window.location.pathname,
            });
          `,
          }}
      />
  </>
  );
}