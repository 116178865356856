import React from "react";
import clsx from "clsx";
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import { Chip } from "../chip";
import _classes from "./select-multiple.module.scss";

export interface SelectOption {
  id: string | number;
  name: string;
}

export interface SelectMultipleProps extends React.HTMLAttributes<HTMLElement> {
  values: Array<SelectOption>;
  options: Array<SelectOption>;
  displaySelected?: boolean;
  onChange: any;
}

function SelectMultiple({
  className,
  placeholder = "Select option",
  values,
  options,
  displaySelected = true,
  onChange,
}: SelectMultipleProps) {
  const unselectOption = (optionId: string | number) =>
    onChange(values.filter(({ id }) => id !== optionId));

  return (
    <div className={clsx(_classes.root, className)}>
      <Listbox value={values} onChange={onChange} multiple>
        <div className={_classes.inner}>
          <Listbox.Button className={_classes.button}>
            <span className={_classes.placeholder}>{placeholder}</span>
            <span className={_classes.chevron}>
              <ChevronUpDownIcon
                className={_classes.chevronIcon}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={React.Fragment}
            leave={_classes.transitionLeave}
            leaveFrom={_classes.transitionFrom}
            leaveTo={_classes.transitionTo}
          >
            <Listbox.Options className={_classes.options}>
              {options.map((option: SelectOption) => (
                <Listbox.Option
                  key={option.id}
                  value={option}
                  className={({ active }) =>
                    clsx(_classes.option, { [_classes.optionActive]: active })
                  }
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={clsx(_classes.optionText, {
                          [_classes.optionSelected]: selected,
                        })}
                      >
                        {option.name}
                      </span>
                      {selected ? (
                        <span className={_classes.checkMark}>
                          <CheckIcon aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {displaySelected ? (
        <div className={_classes.selectedContainer}>
          {values.map(({ id, name }) => (
            <Chip key={id} value={name} onClick={() => unselectOption(id)} />
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default SelectMultiple;
