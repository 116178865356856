import React from "react";
import clsx from "clsx";
import useSWR, { Key } from "swr";
import { Combobox } from "@headlessui/react";
import { Button, CloseIcon } from "ui";
import { Loader } from "../loader";
import _classes from "./autosuggest.module.scss";

export interface AutosuggestProps extends React.HTMLAttributes<HTMLElement> {
  value?: any;
  placeholder?: string;
  classes?: {
    root?: string;
    options?: string;
    option?: string;
    input?: string;
  };
  showLoader?: boolean;
  allowEmptyQuery?: boolean;
  autoFocus?: boolean;
  startAdornment?: any;
  endAdornment?: any;
  fetcher?: (query: string) => Promise<any>;
  renderSuggestion?: Function;
  getSuggestionDisplayValue?: Function;
  onSuggestionSelected?: Function;
}

export interface AutosuggestSuggestion {
  id: string;
  name: string;
}

function Autosuggest({
  id,
  value: controlledValue,
  placeholder = "Type to search",
  showLoader = false,
  allowEmptyQuery = false,
  autoFocus = false,
  classes = {},
  startAdornment = null,
  endAdornment = null,
  renderSuggestion = (s: AutosuggestSuggestion) => s,
  getSuggestionDisplayValue = (s: AutosuggestSuggestion) => s ? s.id : '',
  onSuggestionSelected,
  fetcher = () => Promise.resolve(null),
}: AutosuggestProps) {
  const firstRender = React.useRef(true);
  const [query, setQuery] = React.useState("");
  const [selectedSuggestion, setSelectedSuggestion] = React.useState(controlledValue);
  const [loading, setIsLoading] = React.useState(false);
  const _fetcher = () => {
    if (firstRender.current) {
      firstRender.current = false;
    }
    if (selectedSuggestion && query === "") {
      setSelectedSuggestion(null);
    }
    if ((!allowEmptyQuery || firstRender.current) && query === "") {
      return Promise.resolve([]);
    }
    if (showLoader) {
      setIsLoading(true);
    }
    const promise = fetcher(query).then((response) => {
      if (!response.ok) {
        return Promise.resolve([]);
      }
      return response.json();
    });
    if (showLoader) {
      promise.finally(() => setIsLoading(false));
    }
    return promise;
  };
  const { data: suggestions = [], error } = useSWR([id, query], _fetcher);

  React.useEffect(() => {
    if (controlledValue === undefined || controlledValue === selectedSuggestion) {
      return;
    }
    setSelectedSuggestion(controlledValue);
  }, [controlledValue, selectedSuggestion]);

  React.useEffect(() => {
    if (firstRender.current || controlledValue === selectedSuggestion) {
      return;
    }
    if (onSuggestionSelected) {
      onSuggestionSelected(selectedSuggestion);
    }
  }, [onSuggestionSelected, selectedSuggestion, controlledValue]);

  return (
    <div className={clsx(_classes.root, classes.root)}>
      <Combobox value={selectedSuggestion} onChange={setSelectedSuggestion}>
        {startAdornment ? (
          <div className={_classes.startAdornment}>{startAdornment}</div>
        ) : null}
        <Combobox.Input
          autoFocus={autoFocus}
          className={clsx(_classes.input, classes.input)}
          placeholder={placeholder}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(suggestion) => getSuggestionDisplayValue(suggestion)}
        />
        {endAdornment ? (
          <div className={_classes.endAdornment}>{endAdornment}</div>
        ) : null}
        <div className={_classes.endAdornment}>
          {loading ? <Loader className={_classes.loader} /> : null}
          {!loading && selectedSuggestion ? (
            <Button
              className={_classes.clearBtn}
              onClick={() => setSelectedSuggestion(null)}
            >
              <CloseIcon />
            </Button>
          ) : null}
        </div>
        <Combobox.Options className={clsx(_classes.options, classes.options)}>
          {suggestions.map((suggestion: AutosuggestSuggestion) => (
            <Combobox.Option
              key={suggestion.id}
              value={suggestion}
              as={React.Fragment}
            >
              {({ active, selected }) => (
                <li
                  className={clsx(_classes.option, classes.option, {
                    [_classes.active]: active,
                  })}
                >
                  {renderSuggestion(suggestion)}
                </li>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox>
    </div>
  );
}

export default Autosuggest;
