import React, { MouseEventHandler } from "react";
import clsx from "clsx";
import CloseIcon from "../icons/CloseIcon";
import Button from "../button/Button";
import _classes from "./drawer.module.scss";

interface PropTypes {
  children: any;
  open: boolean;
  onCloseRequested?: MouseEventHandler;
}

function Drawer({
  children,
  open = false,
  onCloseRequested = () => null,
}: PropTypes) {
  return (
    <div className={clsx(_classes.root, { [_classes.open]: open })}>
      <Button onClick={onCloseRequested}>
        <CloseIcon className={_classes.closeBtn} />
      </Button>
      {children}
    </div>
  );
}

export default Drawer;
