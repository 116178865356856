import React, { SyntheticEvent } from "react";
import clsx from "clsx";
import Button from "../button/Button";
import CloseIcon from "../icons/CloseIcon";
import ReactPortal from "../react-portal/ReactPortal";
import _classes from "./modal.module.scss";

interface ModalProps extends React.HTMLAttributes<HTMLElement> {
  classes?: {
    root?: string;
    content?: string;
  };
  isOpened?: boolean;
  showCloseBtn?: boolean;
  wrapperId?: string;
  onClose?: Function;
}

export default function Modal({
  className,
  classes = {},
  children,
  isOpened = false,
  showCloseBtn = true,
  wrapperId = "modal-container",
  onClose,
}: ModalProps) {
  const [localIsOpened, setLocalIsOpened] = React.useState(isOpened);
  const handleClose = React.useCallback(() => {
    setLocalIsOpened(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  React.useEffect(() => {
    if (isOpened !== localIsOpened) {
      setLocalIsOpened(isOpened);
    }
  }, [isOpened, localIsOpened]);

  React.useEffect(() => {
    const closeOnEscapeKey = (event: KeyboardEvent) => {
      if (event.key !== "Escape") {
        return;
      }
      handleClose();
    };
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => document.body.removeEventListener("keydown", closeOnEscapeKey);
  }, [handleClose]);
  if (!localIsOpened) return null;
  return (
    <ReactPortal wrapperId={wrapperId}>
      <div className={clsx(_classes.root, classes.root, className)}>
        <div className={clsx(_classes.content, classes.content)}>
          {showCloseBtn ? (
            <Button onClick={handleClose} className={_classes.closeBtn}>
              <CloseIcon />
            </Button>
          ) : null}
          {children}
        </div>
      </div>
    </ReactPortal>
  );
}
